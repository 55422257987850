<template>
  <content-container
    title="Run Compliance"
    description="Choose a compliance type, provider, and fiduciary from the options below and select Run Compliance to start the process."
    class="operations-compliance"
  >
    <fade-transition>
      <mds-loader v-if="loading" />
      <mds-form v-else>
        <mds-select
          v-model="selectedComplianceType"
          label="Compliance Type:"
          :options="complianceList"
          :disabled="disableEdit"
        />
        <mds-select
          label="Provider:"
          :options="providerList"
          :disabled="disableSelectProvider"
          @input="handleSelectedProvider"
        />
        <mds-select
          v-model="selectedFiduciary"
          label="Fiduciary:"
          :options="fiduciaryList"
          :disabled="disableSelectFiduciary"
        />
        <mds-button
          variation="primary"
          :disabled="disableSubmit"
          type="button"
          class="operations-compliance__open-dialog-btn"
          @click="openDialog"
        >
          Run Compliance
        </mds-button>
      </mds-form>
    </fade-transition>
    <mds-dialog
      v-model="dialogOpen"
      action-required
      width="500px"
      title="Confirm Compliance Run"
    >
      <!-- Default slot -->
      <template>
        <div
          v-if="dialogState === DialogStates.DEFAULT"
          class="operations-compliance__dialog-content--default"
        >
          Are you sure you want to run compliance based on the below selections?
          <mds-data-table
            :header-configs="tableHeaders"
            :row-data="tableData"
            size="small"
            class="operations-compliance__table"
          />
        </div>
        <div
          v-else-if="dialogState === DialogStates.ERROR"
          class="operations-compliance__dialog-content"
        >
          <img
            :src="`${publicPath}images/icons/alert.svg`"
            class="operations-compliance__dialog-content-icon"
          >
          <heading
            :level="1"
            class="operations-compliance__dialog-content-heading"
          >
            Upload Error
          </heading>
          <p class="operations-compliance__dialog-content-description">
            {{ complianceErrorMessage }}
          </p>
        </div>
        <div
          v-else-if="dialogState === DialogStates.SUCCESSFUL"
          class="operations-compliance__dialog-content"
        >
          <img
            :src="`${publicPath}images/icons/check.svg`"
            class="operations-compliance__dialog-content-icon"
          >
          <heading
            :level="1"
            class="operations-compliance__dialog-content-heading"
          >
            {{ complianceStatus }}
          </heading>
        </div>
      </template>
      <!-- Right actions slot -->
      <template v-slot:mds-dialog-actions-right>
        <mds-button-container
          v-if="dialogState === DialogStates.DEFAULT"
          right-aligned
        >
          <mds-button
            type="button"
            variation="secondary"
            class="operations-compliance__dialog-content-cancel-btn"
            @click="closeDialog"
          >
            Cancel
          </mds-button>
          <mds-button
            type="button"
            variation="primary"
            class="operations-compliance__dialog-content-run-compliance-btn"
            @click.prevent="submitCompliance"
          >
            Run
          </mds-button>
        </mds-button-container>
        <mds-button-container
          v-else
          right-aligned
        >
          <mds-button
            type="button"
            variation="secondary"
            @click="closeDialog"
          >
            Close Dialog
          </mds-button>
          <mds-button
            :href="$router.resolve({ name: 'operations-historical' }).href"
            variation="primary"
          >
            View Historical
          </mds-button>
        </mds-button-container>
      </template>
    </mds-dialog>
  </content-container>
</template>

<script>
import ContentContainer from '../../components/ContentContainer/ContentContainer.vue'
import FadeTransition from '../../components/Transitions/FadeTransition.vue'
import { GET_PROVIDERS, GET_FIDUCIARIES_FROM_PROVIDER, GET_COMPLIANCE_RUN } from '../../graqhql/queries'
import { MdsButton, MdsButtonContainer } from '@mds/button'
import { isEmpty } from 'lodash'
import MdsForm from '@mds/form'
import MdsSelect from '@mds/select'
import RoleAccess from '../../services/role-access-service'
import MdsLoader from '@mds/loader'
import MdsDialog from '@mds/dialog'
import { MdsDataTable } from '@mds/data-table'
import Heading from '../../components/Heading/Heading'
import { NO_CACHE } from '../../graqhql/fetch-policies'

const INITIAL_SELECTED_COMPLIANCE_TYPE = ''
const INITIAL_SELECTED_PROVIDER = {}
const INITIAL_SELECTED_FIDUCIARY = ''
const INITIAL_COMPLIANCE_STATUS = ''
const COMPLIANCE_LIST = [{ id: 'Plan' }, { id: 'Menu' }]

const DialogStates = {
  DEFAULT: null,
  SUCCESSFUL: 'successful',
  ERROR: 'error',
}

const TABLE_HEADERS = [
  {
    fieldName: 'compliance',
    text: 'Compliance Type',
  },
  {
    fieldName: 'provider',
    text: 'Provider',
  },
  {
    fieldName: 'fiduciary',
    text: 'Fiduciary',
  },
]

export default {
  components: {
    ContentContainer,
    FadeTransition,
    MdsForm,
    MdsSelect,
    MdsDialog,
    MdsButton,
    MdsButtonContainer,
    MdsLoader,
    MdsDataTable,
    Heading,
  },
  apollo: {
    fiduciariesFromProvider: {
      query: GET_FIDUCIARIES_FROM_PROVIDER,
      variables () {
        return {
          id: this.selectedProvider.id,
        }
      },
      skip () {
        return this.isSelectedProviderEmpty
      },
    },
    providers: {
      query: GET_PROVIDERS,
      variables () {
        return {
          selectedView: RoleAccess.selectedView,
        }
      },
    },
  },
  data () {
    return {
      providers: [],
      fiduciariesFromProvider: [],
      complianceErrorMessage: null,
      publicPath: process.env.BASE_URL,
      DialogStates,
      dialogState: DialogStates.DEFAULT,
      dialogOpen: false,
      selectedComplianceType: INITIAL_SELECTED_COMPLIANCE_TYPE,
      selectedProvider: INITIAL_SELECTED_PROVIDER,
      selectedFiduciary: INITIAL_SELECTED_FIDUCIARY,
      complianceStatus: INITIAL_COMPLIANCE_STATUS,
      disableEdit: RoleAccess.disableEdit,
      selectedView: RoleAccess.selectedView,
      tableHeaders: TABLE_HEADERS,
    }
  },
  computed: {
    loading () {
      return this.$apollo.queries.providers.loading
    },
    providerList () {
      // we are saving the provider id and code for fiduciariesFromProvider Query and also for the table
      return [{ text: 'Select an Option' }, ...this.providers.map(({ id, code }) => ({ text: code, value: JSON.stringify({ id, code }) }))]
    },
    fiduciaryList () {
      return [{ text: 'Select an Option' }, ...this.fiduciariesFromProvider.map(({ name, code }) => ({ text: name, value: code }))]
    },
    complianceList () {
      return [{ text: 'Select an Option' }, ...COMPLIANCE_LIST.map(({ id }) => ({ text: id, value: id }))]
    },
    disableSubmit () {
      if (this.selectedComplianceType === INITIAL_SELECTED_COMPLIANCE_TYPE) {
        this.cleanSelections()
      }
      return this.disableEdit || !this.selectedFiduciary
    },
    disableSelectFiduciary () {
      return this.disableEdit || this.$apollo.queries.fiduciariesFromProvider.loading || isEmpty(this.selectedProvider)
    },
    disableSelectProvider () {
      return this.disableEdit || !this.selectedComplianceType
    },
    isSelectedProviderEmpty () {
      return isEmpty(this.selectedProvider)
    },
    tableData () {
      return [{
        id: 0,
        compliance: this.selectedComplianceType,
        provider: this.selectedProvider?.code,
        fiduciary: this.selectedFiduciary,
      }]
    },
  },
  watch: {
    selectedProvider () {
      this.selectedFiduciary = INITIAL_SELECTED_FIDUCIARY
    },
  },
  methods: {
    handleSelectedProvider (e) {
      this.selectedProvider = JSON.parse(e.target.value)
    },
    cleanSelections () {
      this.selectedComplianceType = INITIAL_SELECTED_COMPLIANCE_TYPE
      this.selectedProvider = INITIAL_SELECTED_PROVIDER
      this.selectedFiduciary = INITIAL_SELECTED_FIDUCIARY
    },
    openDialog () {
      this.dialogOpen = true
    },
    closeDialog () {
      this.dialogState = this.DialogStates.DEFAULT
      this.dialogOpen = false
    },
    async submitCompliance () {
      try {
        const complianceOptions = {
          providerCode: this.selectedProvider?.code,
          fiduciaryCode: this.selectedFiduciary,
          complianceType: this.selectedComplianceType,
        }

        // Use 'no-cache' fetch policy to get new trace_id and avoid cached result
        const { data: { complianceRun } } = await this.$apollo.query({
          query: GET_COMPLIANCE_RUN,
          variables: {
            options: complianceOptions,
          },
          fetchPolicy: NO_CACHE,
        })

        this.complianceStatus = complianceRun.status
        this.dialogState = this.DialogStates.SUCCESSFUL
      } catch (error) {
        this.complianceErrorMessage = error.message || error?.graphQLErrors[0]?.message
        this.dialogState = this.DialogStates.ERROR
      }
    },
  },
}
</script>

<style lang="scss">
  .operations-compliance form {
    max-width: 500px;
  }

  .operations-compliance__table {
    margin-top: 20px;
    div {
      margin-bottom: 0;
    }
  }

  .operations-compliance__dialog-content {
    padding-top: $mds-space-1-x;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .operations-compliance__dialog-content--default {
    align-items: flex-start;
  }

  .operations-compliance__dialog-content-icon {
    margin: 0 0 $mds-space-3-x;
    max-width: 60px;
  }

  .operations-compliance__dialog-content-heading {
    @include mds-level-4-heading();
    margin: 0 0 $mds-space-1-x;
  }

  .operations-compliance__dialog-content-description {
    margin: 0 0 $mds-space-1-x;
  }
</style>
